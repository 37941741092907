<template>
    <div class="vx-row">
        <div class="vx-col md:w-2/3">
            <canvas :class="{'border-image' : !checkImg, '' : checkImg}" width="640" height="480" @contextmenu.prevent @mousedown="point_it" ref="canvas"
                    style="cursor:crosshair"></canvas>
        </div>
        <div class="vx-col md:w-1/3">
            <h6>{{ $t('menuIntrusion.note') }}</h6>
            <h6>{{ $t('menuIntrusion.painting') }}</h6>
            <h6>{{ $t('menuIntrusion.click') }}</h6>
            <h6>{{ $t('menuIntrusion.rightClick') }}</h6>
            <vs-button class="mt-5" color="warning" size="small" @click="undo">{{ $t('button.back') }}</vs-button>
            <vs-button class="mt-5 ml-5" size="small" @click="clear_canvas">{{ $t('menuIntrusion.clearIdentified') }}
            </vs-button>
            <!--              <vs-textarea v-model="valuePoint"></vs-textarea>-->
        </div>
    </div>
</template>
<style scoped>
</style>

<script>
export default {
    name: 'CanvasPoint',
    data() {
        return {
            messageError: false,
            isUpdate: false,
            perimeter: new Array(),
            complete: false,
            canvas: null,
            ctx: null,
        }
    },
    props: {
        checkImg: {
            type: Boolean,
            default: true
        },
        myImg: null,
        width: '640',
        height: '480',
        valuePoint: null,
    },
    components: {},
    computed: {},
    async created() {
    },
    methods: {
        startImg(with_draw) {
            this.canvas = this.$refs.canvas;
            this.ctx = this.canvas.getContext("2d");
            let bg = new Image();
            bg = this.myImg;
            this.ctx.drawImage(bg, 0, 0, this.canvas.width, this.canvas.height);
            if (with_draw == true) {
                this.draw(false);
                this.canvat_poi();
            }
        },
        line_intersects(p0, p1, p2, p3) {
            let s1_x, s1_y, s2_x, s2_y;
            s1_x = p1['x'] - p0['x'];
            s1_y = p1['y'] - p0['y'];
            s2_x = p3['x'] - p2['x'];
            s2_y = p3['y'] - p2['y'];

            let s, t;
            s = (-s1_y * (p0['x'] - p2['x']) + s1_x * (p0['y'] - p2['y'])) / (-s2_x * s1_y + s1_x * s2_y);
            t = (s2_x * (p0['y'] - p2['y']) - s2_y * (p0['x'] - p2['x'])) / (-s2_x * s1_y + s1_x * s2_y);

            if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
                // Collision detected
                return true;
            }
            return false; // No collision
        },
        point(x, y) {
            this.ctx.fillStyle = "red";
            this.ctx.strokeStyle = "red";
            this.ctx.fillRect(x - 2, y - 2, 4, 4);
            this.ctx.moveTo(x, y);
        },
        undo() {
            if(this.perimeter.length <= 0){
                return;
            }
            this.ctx = undefined;
            this.perimeter.pop();
            this.complete = false;
            this.startImg(false);
            this.draw(false);
        },
        clear_canvas() {
            this.ctx = undefined;
            this.perimeter = new Array();
            this.complete = false;
            this.valuePoint = [];
            this.$eventBus.$emit("update-point-ai", this.valuePoint);
            this.$eventBus.$emit('delete_img_selected', this.valuePoint);
            this.startImg();
        },
        draw(end) {
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = "white";
            this.ctx.lineCap = "square";
            this.ctx.beginPath();

            for (let i = 0; i < this.perimeter.length; i++) {
                if (i == 0) {
                    this.ctx.moveTo(this.perimeter[i]['x'], this.perimeter[i]['y']);
                    end || this.point(this.perimeter[i]['x'], this.perimeter[i]['y']);
                } else {
                    this.ctx.lineTo(this.perimeter[i]['x'], this.perimeter[i]['y']);
                    end || this.point(this.perimeter[i]['x'], this.perimeter[i]['y']);
                }
            }
            if (end) {
                this.ctx.lineTo(this.perimeter[0]['x'], this.perimeter[0]['y']);
                this.ctx.closePath();
                this.ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
                this.ctx.fill();
                this.ctx.strokeStyle = 'blue';
                this.complete = true;
            }
            this.ctx.stroke();
            // print coordinates
            if (this.perimeter.length == 0) {
                this.valuePoint = [];
            } else {
                this.valuePoint = this.perimeter;
            }
        },
        check_intersect(x, y) {
            if (this.perimeter.length < 4) {
                return false;
            }
            let p0 = new Array();
            let p1 = new Array();
            let p2 = new Array();
            let p3 = new Array();

            p2['x'] = this.perimeter[this.perimeter.length - 1]['x'];
            p2['y'] = this.perimeter[this.perimeter.length - 1]['y'];
            p3['x'] = x;
            p3['y'] = y;

            for (let i = 0; i < this.perimeter.length - 1; i++) {
                p0['x'] = this.perimeter[i]['x'];
                p0['y'] = this.perimeter[i]['y'];
                p1['x'] = this.perimeter[i + 1]['x'];
                p1['y'] = this.perimeter[i + 1]['y'];
                if (p1['x'] == p2['x'] && p1['y'] == p2['y']) {
                    continue;
                }
                if (p0['x'] == p3['x'] && p0['y'] == p3['y']) {
                    continue;
                }
                if (this.line_intersects(p0, p1, p2, p3) == true) {
                    return true;
                }
            }
            return false;
        },
        canvat_poi(x, y) {
            x = this.perimeter[0]['x'];
            y = this.perimeter[0]['y'];
            if (this.check_intersect(x, y)) {
                return this.$vs.notify({
                    text: this.$t('menuIntrusion.identificationZone'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
                //alert('The line you are drowing intersect another line');
            }
            this.draw(true);
            //alert('Polygon closed');
            this.$eventBus.$emit("update-point-ai", this.valuePoint);
            if (this.isUpdate == true) {
                return this.$vs.notify({
                    text: this.$t('menuIntrusion.finishedArea'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }

        },

        point_it(event) {
            if (!this.checkImg) {
                if (!this.messageError) {
                    this.$vs.notify({
                        text: this.$t('menuIntrusion.imgNotArea'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                    this.messageError = true;
                }
                return;
            }
            if (this.complete) {
                this.$vs.notify({
                    text: this.$t('menuIntrusion.paintedArea'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
                //alert('Polygon already created');
                return false;
            }
            let rect, x, y;
            if (event.ctrlKey || event.which === 3 || event.button === 2) {
                // Cho phép nhập 2 điểm
                // if (this.perimeter.length == 2) {
                //   alert('You need at least three points for a polygon');
                //   return false;
                // }
                this.canvat_poi(x, y);
            } else {
                rect = this.canvas.getBoundingClientRect();
                x = event.clientX - rect.left;
                y = event.clientY - rect.top;
                if (this.perimeter.length > 0 && x == this.perimeter[this.perimeter.length - 1]['x'] && y == this.perimeter[this.perimeter.length - 1]['y']) {
                    // same point - double click
                    return false;
                }
                if (this.check_intersect(x, y)) {
                    return this.$vs.notify({
                        text: this.$t('menuIntrusion.identificationZone'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    // alert('The line you are drowing intersect another line');
                }
                this.perimeter.push({'x': x, 'y': y});
                this.draw(false);
                this.isUpdate = true
                return false;
            }
        },
    },
    watch: {
        complete: function (val) {
            this.$eventBus.$emit('can_next_step_restric', val)
        }

    },
    mounted() {
        this.complete = (this.valuePoint || []).length > 0;
        if (this.valuePoint && this.valuePoint.length > 0) {
            this.perimeter = this.valuePoint;
            this.startImg(true);
        } else {
            this.startImg();
        }
        this.$eventBus.$on('delete_img_camera_selected', event => {
            if (event) {
                this.clear_canvas()
            }
        })
    }
}
</script>
<style scoped>
.border-image {
    border: 1px solid #ccc;
}
</style>

